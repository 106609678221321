@import 'Styles';

.ListenNow {
  button {
    background: transparent;
    border: 2px solid $theme_textColor;
    border-radius: 8px;
    color: $theme_textColor;
    font-size: 40px;
    padding: 8px;

    &:hover, &:focus {
      &:not( :disabled ) {
        background: $theme_textColor;
        color: $theme_bgColor;
      }
    }

    &:disabled {
      opacity: 0.1;
    }
  }

  iframe {
    height: 300px;
    width: 90vw;
    max-width: 640px;
  }
}
